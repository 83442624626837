// Keep in sync with DefaultRegistryProjects in backend
import {AccessOption} from '../../../util/permissions';
import {SelectionPathType} from '../../../util/urls';
import * as urls from '../../../util/urls';
import {RegistryAccessOptionType} from './permissionUtils';
import {GQLRegistryProjectType} from './types';

// Keep DISALLOW_CUSTOM_REGISTRY_PROJECT_NAMES in sync with DEFAULT_REGISTRY_PROJECT_TYPES
export const DEFAULT_REGISTRY_PROJECT_TYPES = ['model', 'dataset'];

export const REGISTRY_PROJECT_PREFIX = 'wandb-registry-';

export type registryProject = {
  isRegistryProject: boolean;
  registryName?: string;
};

export const isOrgRegistryProjectName = (projectName: string | undefined) => {
  const {isRegistryProject} = checkRegistryProject(projectName);
  return isRegistryProject;
};

export const checkRegistryProject = (
  projectName: string | undefined
): registryProject => {
  if (!projectName) {
    return {isRegistryProject: false, registryName: undefined};
  }
  // Function to check if project is a registry project
  const isRegistryProject = projectName.startsWith(REGISTRY_PROJECT_PREFIX);
  const index = REGISTRY_PROJECT_PREFIX.length;
  const registryName = isRegistryProject
    ? projectName.substring(index)
    : undefined;
  return {isRegistryProject, registryName};
};

type User = {
  id: string;
  name: string;
  photoUrl?: string | null;
};

export type globalRegistryType = {
  id: string;
  owner?: User;
  entityName: string;
  orgName: string;
  registryName: string;
  registryProjectName: string;
  registryProjectID: string;
  description: string;
  lastActive?: string | null;
  createdAt?: string;
  types: string[] | null;
  collectionCount: number;
  access: RegistryAccessOptionType;
  allowAllArtifactTypesInRegistry: boolean;
  searchTerm?: string;
  protectedAliases: string[];
};

/**
 * Temporary util till we deprecate Model registry
 * to handle entity-scoped urls
 */
export function resolveRegistryUrl(
  isOrgRegistry: boolean,
  orgName: string,
  registryName?: string,
  selectionPath?: SelectionPathType,
  entityName?: string,
  selectedCollectionView?: string,
  selectedMembershipIdentifier?: string,
  selectedTab?: string
) {
  if (isOrgRegistry || registryName !== 'model') {
    return urls.globalRegistry(
      orgName,
      registryName,
      selectionPath,
      selectedCollectionView,
      selectedMembershipIdentifier,
      selectedTab
    );
  }
  return urls.objectRegistry(
    registryName,
    selectionPath,
    entityName,
    selectedCollectionView,
    selectedMembershipIdentifier,
    selectedTab
  );
}

// Get the GlobalRegistryType struct from a registry project
export function getGlobalRegistryType(
  registryProject: GQLRegistryProjectType,
  regName: string,
  entityName: string,
  orgName: string
): globalRegistryType {
  const typeNodes = registryProject.artifactTypes?.edges;
  const types: string[] = [];
  typeNodes?.forEach(type => {
    if (type.node?.name != null) {
      types.push(type.node?.name);
    }
  });
  const collectionCount = registryProject.totalArtifactCollections;
  const registryOwner =
    registryProject.user == null ? undefined : registryProject.user;
  return {
    id: registryProject.id,
    owner: registryOwner,
    entityName,
    orgName,
    registryName: regName,
    registryProjectName: registryProject.name,
    description: registryProject.description ?? '',
    lastActive: registryProject.lastActive,
    createdAt: registryProject.createdAt,
    types,
    collectionCount,
    access:
      registryProject.access === 'RESTRICTED'
        ? AccessOption.Restricted
        : AccessOption.Private,
    allowAllArtifactTypesInRegistry:
      registryProject.allowAllArtifactTypesInRegistry,
    registryProjectID: registryProject.id,
    protectedAliases: registryProject.protectedArtifactAliases.map(
      alias => alias.alias
    ),
  };
}
